import React, { useState } from "react";
import "./ContactForm.css"; // Import the CSS file

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [resultMessage, setResultMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResultMessage(""); // Reset the message on form submit

    // Submit the form data (replace placeholder URL with your actual handler)
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          access_key: "260525ea-fff7-430d-b559-82eaf7a3a2d0", // Replace with your access key
          subject: "New Submission from Web3Forms",
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setResultMessage("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        setResultMessage("Failed to send message, please try again.");
      }
    } catch (error) {
      setResultMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex-container">
      <div className="form-container">
        <div className="form-header">
          <p>Fill up the form below to send us a message.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div  className="form-first">

          
            <div className="form-group ff">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="John Doe"
                required
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group ff">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="you@company.com"
                required
                value={formData.email}
                onChange={handleChange}
              />
            </div>{" "}
          </div>
          <div className="form-group ff2">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="text"
              name="phone"
              id="phone"
              placeholder="+1 (555) 1234-567"
              required
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group ff2">
            <label htmlFor="message">Your Message</label>
            <textarea
              rows="5"
              name="message"
              id="message"
              placeholder="Your Message"
              required
              value={formData.message}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="submit-button">
            Send Message
          </button>
          {resultMessage && <p className="result-message">{resultMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
