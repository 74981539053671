import React from "react";  
import Drawer from "react-modern-drawer";  
import { IoArrowBack } from "react-icons/io5";  
import { Link } from "react-router-dom";  
import "./Navbar.css";  
import logo from  "../Assets/vkrlogo-removebg-preview1.png";  

const Drawerreact = (props) => {  
  return (  
    <div className="drawer-container">  
      <Drawer open={props.open} onClose={props.onClose} direction="left">  
        <IoArrowBack onClick={props.onClose} className="d-back" />  
        <div className="navbar-logo">   
          <img src={logo} alt="logo" />   
        </div>  

        <div className="d-menu" onClick={props.onClose}>  
          <ul>  
            <li>  
              <Link to="/">HOME</Link>  
            </li>  
            <li>  
              <Link to="/about">ABOUT US</Link>  
            </li>  
            <li>  
              <Link to="/team">MEET OUR TEAM</Link>  
            </li>  
            <li>  
              <Link to="/service">OUR SERVICES</Link>  
            </li>  
            <li>  
              <Link to="/contact">CONTACT US</Link>  
            </li>  
          </ul>  
        </div>  
      </Drawer>  
    </div>  
  );  
};  

export default Drawerreact;