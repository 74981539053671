import React from "react";  
import "./Footer.css";  

const Footer = () => {  
  return (  
    <div className="footer-container">  
      <div className="footer-left">  
        <h2>ABOUT US</h2>  
        <p>  
        We, “VKR UNIVERSAL CORPORATION’’ can be utilized to meet your
        exact requirements, whether you are interested in “Global Sourcing, Global Procurement and International Business Development’’.
        </p>  
        <div className="social-icons">  
          <a href="#" aria-label="Twitter">  
            <i className="fab fa-twitter" />  
          </a>  
          <a href="#" aria-label="LinkedIn">  
            <i className="fab fa-linkedin" />  
          </a>  
        </div>  
      </div>  
 

      <div className="footer-right">  
        <h2>ADDRESS</h2>  
        <p>  
          A-2/703, 7th Floor,Palmera, Vadsar Vishwamitri Road,<br /> Near Royal Green,  
          Vadsar, Vadodara, Post Code: 390011,<br /> Gujarat State, INDIA.  
        </p>  
      </div>  
    </div>  
  );  
};  

export default Footer;