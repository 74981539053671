import React from "react";
import team1 from "../Assets/team1.jpg";
import "./Team1.css";
const Team1 = () => {
  return (
    <>
      <div className="container1">
        <div className="image">
          <img alt="vikram desai" src={team1} width="400" height="400" />
        </div>
        <div className="content">
          <span className="vikram">VIKRAM DESAI</span>
          <br />
          <span>PRESIDENT - BUSINESS DEVELOPMENT</span>
          <br />
          <br />

          <p>
            “VKR UNIVERSAL CORPORATION’’ is a leader in “Global Sourcing, Global
            Procurement and International Business Development’’ and it is fully
            controlled by VIKRAM DESAI (President - Business Development at “VKR
            UNIVERSAL CORPORATION’’).
          </p>
          <br />

          <p>
            With “Bachelor of Science (B.Sc.)’’ in “CHEMISTRY’’ and “Master of
            Business Administration (MBA)’’ in “MARKETING’’, he is a senior
            “Global Sourcing, Global Procurement and International Business
            Development’’ professional with 24 + years of diverse experience.
          </p>
          <br />
          <p>
            • He has worked at the United Kingdom and European Union for so many
            years.
            <br />
            <br />
            • He has travelled to more than 90 countries across the globe for
            work purposes.
            <br />
            <br />
            • He is very fluent in “ENGLISH, PORTUGUESE, SPANISH, ITALIAN, HINDI
            & GUJARATI’’.
            <br />
            <br />
            • He has led Multinational and Multicultural teams.
            <br />
            <br />
            • Kindly visit the below link to know his Educational
            Credential.
          </p>
        </div>
      </div>
      <div className="degree">
        <div>
          <h2>EDUCATIONAL CREDENTIAL</h2>
          <a href="/docs/myfile3.pdf" target="_blank" rel="noopener noreferrer">
            Click here to download the PDF
          </a>
        </div>
        <div>
          <h2>SKILL DEVELOPMENT COURSES</h2>
          <a href="/docs/myfile2.pdf" target="_blank" rel="noopener noreferrer">
            Click here to download the PDF
          </a>
        </div>
      </div>
    </>
  );
};

export default Team1;
