import React from "react";
import Navbar from "./Component/Navbar";
import Hero from "./Component/Hero";
import About from "./Component/About";

import Services from "./Component/Services";
import Contact from "./Component/Contect";
import Footer from "./Component/Footer";
import Team from "./Component/Team";
import NewsTikker from "./Component/NewsTikker";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Team1 from "./Component/Team1";
const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/service" element={<Services />} />
        <Route path="/newstikker" element={<NewsTikker />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
         <Route path="/footer" element={<Footer/>}/>
         <Route path="/teamleader" element={<Team1/>}/>
      </Routes>
      
    </Router>
  );
};

export default App;
