import React  from "react";
import "./Contect.css";
import NewsTikker from "./NewsTikker";
import { MdOutlineAddIcCall } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import ContactForm from "./ContactForm";


const Contect = () => {

  

  

  const contacts = [
    {
      name: "VIKRAM DESAI",
      title: "President - Business Development",
      mobile: "+91 9512267528",
      email: "vikram.desai@vkruniversalcorporation.com",
    },
    {
      name: "KUNDAN PATEL",
      title: "President - Business Operations",
      mobile: "+91 8238009617",
      email: "kundan.patel@vkruniversalcorporation.com",
    },
    {
      name: "RAJU SHAH",
      title: "President - Business Operations",
      mobile: "+91 7433883131",
      email: "raju.shah@vkruniversalcorporation.com",
    },
  ];

  return (
    <div className="contact-page">
      <div className="contact-info">
        <h1>CONTACT US</h1>
        <span className="vkr" >VKR UNIVERSAL CORPORATION</span>
        <br/>
        <span className="vkr1" >
       
          (A leader in Global Sourcing, Global Procurement and International
          Business Development)
        </span>
        <p>
          A-2/703, 7th Floor, Palmera, Vadsar Vishwamitri Road, Near Royal
          Green, Vadsar, Vadodara, Post Code: 390011, Gujarat State, INDIA.
        </p>
      </div>
      
      <div className="team-contacts">
        {contacts.map((contact, index) => (
          <div className="contact-card" key={index}>
            <h3>{contact.name}</h3>
            <p>{contact.title}</p>
            <div className="d-flex">
              <MdOutlineAddIcCall className="icon" size={"20px"} />
              {contact.mobile}
            </div>
            <div className="d-flex">
              <IoMdMail className="icon" size={"20px"} />
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </div>
          </div>
        ))}
      </div>
      <div className="website">
        <a href="https://vkruniversalcorporation.com/">
          www.vkruniversalcorporation.com
        </a>
      </div>
      <ContactForm/>
      <NewsTikker />
    </div>
  );
};

export default Contect;
