import React from "react";
import "./About.css";
import NewsTikker from "./NewsTikker";
import Footer from "./Footer";
import fssi from "../Assets/fssi4.png";
import iso from "../Assets/iso2.png";
import msme from "../Assets/MSME.png";
import iec from "../Assets/iec12.jpg";
import gst from "../Assets/gst2.png";

const About = () => {
  return (
    <>
      <section  className="about">
      <div className="about-vkr" >
      <span className="vkr-a" >VKR UNIVERSAL CORPORATION</span>
      <br/>
      <span className="vkr1-a" >
     
        (A leader in Global Sourcing, Global Procurement and International
        Business Development)
      </span>
      </div>
        <div className="about-container1">
          <p>
            We, “VKR UNIVERSAL CORPORATION’’, are a “Global Sourcing, Global
            Procurement and International Business Development’’ company and we
            are providing “Global Sourcing, Global Procurement and International
            Business Development’’ services all over the world.
          </p>

          <p>
            We, “VKR UNIVERSAL CORPORATION’’, are your “Global Sourcing, Global
            Procurement and International Business Development’’ Partner that
            steps in to make the process easier and be your eyes and ears on the
            ground for your “Global Sourcing, Global Procurement and
            International Business Development’’ requirements.
          </p>

          <p>
            We, “VKR UNIVERSAL CORPORATION’’ can be utilized to meet your exact
            requirements, whether you are interested in “Global Sourcing, Global Procurement and International Business Development’’.
          </p>

          <p>
            We, “VKR UNIVERSAL CORPORATION’’, have been a “Global Sourcing,
            Global Procurement and International Business Development’’ expert
            for over 24 years and at our “VKR UNIVERSAL CORPORATION’’, our
            Global Network to 80+ countries across the globe as well as our
            Global Expertise in “Global Sourcing, Global Procurement and
            International Business Development’’ is providing excellent results.
          </p>
        </div>
      </section>
      <section  className="about2">
        <div className="about-container">
          <div className="about-content">
            <div className="about-item">
              <h4>OUR MISSION</h4>
              <p>
                To become an indispensable partner to our clients by maintaining
                our “Excellence, Integrity, Commitment, Passion and
                Result-Oriented’’ values.
              </p>
            </div>
            <div className="about-item">
              <h4>OUR VISION</h4>
              <p>
                To become a Global Company in “Global Sourcing, Global
                Procurement and International Business Development’’ by covering
                the whole world.
              </p>
            </div>
          </div>
        </div>
        <NewsTikker />
        <div className="certificat">
          <h1>CERTIFICATES</h1>
        </div>
        <div className="container2">
          <div className="item">
            <img
              alt="Icon representing equipment"
              src={fssi}
              width="50"
              height="50"
            />
          </div>
          <div className="item">
            <img
              alt="Icon representing a cup of coffee"
              src={msme}
              width="50"
              height="50"
            />
          </div>
          <div className="item">
            <img
              alt="Icon representing a take-away coffee cup"
              src={iso}
              width="50"
              height="50"
            />
          </div>
          <div className="item iteml">
            <img
              alt="Icon representing coffee beans"
              src={gst}
              width="90"
              height="50"
            />
          </div>
          <div className="item iteml">
            <img
              alt="Icon representing coffee beans"
              src={iec}
              width="90"
              height="50"
            />
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default About;
