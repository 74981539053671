import React, { useState } from "react";  
import { Link } from "react-router-dom";  
import "./Team.css";  
import ceo from "../Assets/ceo.jpg";  
import cto from "../Assets/cto33.jpg";  
import cmo from "../Assets/cto333.jpg";  
import NewsTikker from "./NewsTikker";  
import Footer from "./Footer"; 
import { FaArrowRight } from "react-icons/fa";

const Team = () => {  
  const [hoveredId, setHoveredId] = useState(null); // Track the currently hovered member  

  const teamMembers = [  
    {  
      id: 1,  
      name: "VIKRAM DESAI",  
      role: "PRESIDENT - BUSINESS DEVELOPMENT",  
      image: ceo,  
      details: "Vikram has over 20 years of experience in business development and strategy.",  
    },  
    {  
      id: 2,  
      name: "KUNDAN PATEL",  
      role: "PRESIDENT - BUSINESS OPERATIONS",  
      image: cto,  
      details: "At “VKR UNIVERSAL CORPORATION’’, Business Operations is controlled by KUNDAN PATEL under the observation of VIKRAM DESAI (President - Business Development at “VKR UNIVERSAL CORPORATION’’) and he has 20 + years of experience in Business Operations.",  
    },  
    {  
      id: 3,  
      name: "RAJU SHAH",  
      role: "PRESIDENT - BUSINESS OPERATIONS",  
      image: cmo,  
      details: "At “VKR UNIVERSAL CORPORATION’’, Business Operations is also controlled by RAJU SHAH along with KUNDAN PATEL (President - Business Operations at “VKR UNIVERSAL CORPORATION’’) as a team, under the observation of VIKRAM DESAI (President - Business Development at “VKR UNIVERSAL CORPORATION’’) and he has 30 + years of experience in Business Operations.",  
    },  
  ];  

  return (  
    <section id="team" className="team">  
      <h3>MEET OUR TEAM</h3>  
      <hr />  
      <div className="team-container">  
        {teamMembers.map((member) => (  
          <div  
            key={member.id}  
            className={`team-member ${hoveredId === member.id ? "hovered" : ""}`}  
            onMouseEnter={() => setHoveredId(member.id)}  
            onMouseLeave={() => setHoveredId(null)}  
          >  
            {member.id === 1 ? (  
              <Link to="/teamleader" style={{ textDecoration: "none", color: "inherit" }}>  
                <img src={member.image} alt={member.name} className="team-image" />  
                <h4>{member.name}</h4>  
                <p>{member.role}</p>  
                {hoveredId === 1 && <button className="click-me">READ  MORE <FaArrowRight /></button>}  
              </Link>  
            ) : (  
              <div>  
                <img src={member.image} alt={member.name} className="team-image" />  
                <h4>{member.name}</h4>  
                <p>{member.role}</p>  
                {hoveredId === member.id && <p className="hover-phrase"> {member.details}</p>}  
              </div>  
            )}  
          </div>  
        ))}  
      </div>  
      <br />  
      <NewsTikker />  
      <br />  
      <Footer />  
    </section>  
  );  
};  

export default Team;