import React from "react";
import "./Hero.css";
import NewsTikker from "./NewsTikker";

const Hero = () => {
  return (
    <>
      <section className="hero" id="hero">
        <div className="hero-content">
          <h1>WELCOME TO VKR UNIVERSAL CORPORATION</h1>
          <h2>DEDICATED TO ESTABLISHING GLOBAL BUSINESS CONNECTIVITY !</h2>
          <br/>
          <br/>
          <p>
            
            We, “VKR UNIVERSAL CORPORATION’’, are a “Global Sourcing, Global
            Procurement and International Business Development’’ company and we
            are providing “Global Sourcing, Global Procurement and International
            Business Development’’ services all over the world.
          </p>
        </div>
      </section>

      <NewsTikker />
    </>
  );
};

export default Hero;
