import React, { useEffect, useRef } from 'react';  
import './NewsTikker.css';  

const NewsTikker = () => {  
    const newsItems = [  
        " VKR UNIVERSAL CORPORATION (A leader in Global Sourcing, Global Procurement and International Business Development)",  
        
       
    ];  

    const tickerRef = useRef(null);  

    useEffect(() => {  
        const scrollTicker = () => {  
            const ticker = tickerRef.current;  
            ticker.scrollBy({   
                top: 0,   
                left: ticker.offsetWidth,   
                behavior: 'smooth'   
            });  
        };  

        const intervalId = setInterval(scrollTicker, 3000); // Adjust time as needed  

        return () => {  
            clearInterval(intervalId);  
        };  
    }, []);  

    return (  
        <div className="news-ticker">  
            <div className="ticker-content" ref={tickerRef}>  
                {newsItems.map((item, index) => (  
                    <div key={index} className="ticker-item">  
                        {item}  
                    </div>  
                ))}  
            </div>  
        </div>  
    );  
};  

export default NewsTikker;